.Sidebar {
    
}

.Sidebar-widget {
    margin-bottom: 2em;

    h3 {
        font-size: 1.5em;
        width: 100%;
    }

    ul {
        // Remove bullets
        list-style: none;
        margin-bottom: 0;
        // Remove default space allocated for bullets
        margin-top: 0;
        padding-left: 0;
    }

    li {
        margin-bottom: 0.25em;

        a {
            text-decoration: none;
            display: block;
            cursor: pointer;
            color: $color-primary;

            &:hover,
            &:focus,
            &:active {
                color: $color-primary;
                text-decoration: underline;
            }
        }
    }
}

.Sidebar-header {
    font-size: 1.5em;
}

.Sidebar-image {
}

.textwidget {
    // width: 70%;
    // font-size: 0.9em;
    // line-height: 2em;
    // margin: 0 auto;
}