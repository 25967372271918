.Article {

}

.Article-title {
    font-size: 1.75em;
    margin-bottom: 0;
    margin-top: 0.25em;
}

.Article-meta {
    margin-bottom: 1em;
    font-size: 0.9em;
    color: $color-gray;
}

.Article-figure {
    margin-bottom: 1em;
    position: relative;
    width: calc(100% + 4em);
    margin-left: -2em;
}

.Article-figureImage {
    width: 100%;
}

.Article-figureCaption {
    font-size: 0.7em;
    color: $color-gray; 
    text-align: right;
    margin-top: 0.25em;
    margin-right: 2em;
}

.Article-figureCategory {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-primary-light;
    color: $color-white;
    font-size: 0.8em;
    padding: 0.25em 1em;
    z-index: 2;
    text-decoration: none;
}

.Article-category {
    background-color: $color-primary-light;
    text-decoration: none;
    color: $color-white;
    padding: 0.1em 0.3em 0.2em;
    margin-right: 0.25em;
    font-size: 0.9em;

    &:active,
    &:focus,
    &:hover {
        background-color: $color-primary;
    }
}

.Page-content,
.Article-content {

    > h3 {
        margin-top: 2em;
    }

    > h4 {
        margin-bottom: 0.25em;
        color: $color-gray-dark;
        margin-top: 0;
    }

    p {
        margin-bottom: 1em;
        font-size: 0.9em;
        line-height: 2em;
    }
    
    .aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
    }   

    .alignleft,
    .left {
            margin-right: 1em;
            margin-bottom: 0.35em;
            margin-top: 0.35em;
            float: left;
    }

    .alignright,
    .right {
            margin-left: 1em;
            margin-bottom: 0.35em;
            margin-top: 0.35em;
            float: right;
    }

    blockquote {
        margin-left: 0;
        color: $color-secondary;
        text-align: center;
        font-style: normal;
        padding: 3em 9em;
        
        p {
            font-size: 2em;
            font-family: $font-heading;
            text-transform: uppercase;
            line-height: 1.2;
            margin-bottom: 0;
        }

        cite {
            font-size: 0.9em;
            color: $color-gray-dark;
            display: block;
            padding-right: 9em;
            text-align: right;
        }
    }

    .wp-embed-aspect-16-9 {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        margin: 0 0 1em;
        
        iframe, 
        object, 
        embed {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }
}

.Article-share {
    clear: both;
}