.Card {
    position: relative;
    text-decoration: none;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $color-gray-dark;
    color: $color-white;
    padding: 0.5em;
}

.Card-link {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

.Card--hover {
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {

        .Card-button {
            margin-right: -0.25em;
            margin-bottom: -0.25em;
            padding: 0.25em 1em;
        }

        .Card-figureImage {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}

.Card-figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    overflow: hidden;
}

.Card-figureImage {
    min-height: 100%;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.Card-category {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-primary-light;
    color: $color-white;
    font-size: 0.8em;
    padding: 0em 0.25em 0.25em 1em;
    z-index: 2;
    a {
        text-decoration: none;
    }
}

.category .Card-category {
    display: none;
}

.Card-content {
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1em 1em 0 1em;
}

.Card-title {
    margin-bottom: 0.25;
    color: $color-white;
}

.Card-meta {
    font-size: 0.8em;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
    color: $color-white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span {
        margin-right: 0.25em;
    }

}

.Card-tag {
    color: $color-primary-light;
    text-decoration: none;
    background-color: $color-white;
    padding: 0em 0.3em 0;
    position: relative;
    z-index: 2;
    margin-right: 0.25em;

    &:active,
    &:focus,
    &:hover {
        color: $color-primary;
    }
}


.Card-button {
    background-color: $color-white;
    padding: 0.1em 0.4em;
    color: $color-primary-light;
    font-size: 0.9em;
    margin-left: auto;
    margin-top: auto;
    transition: all 0.3s ease-in-out;
}

.Card--News,
.Card--Nieuws {

    .Card-tag,
    .Card-button {
        color: $color-gray-dark;
    }
    .Card-category {
        background-color: $color-gray-dark;  
    }
}

.Card--Journalist,
.Card--Publicist {
    background-color: $color-primary-light;
    .Card-tag,
    .Card-button {
        color: $color-primary-light;
    }
    .Card-category {
        background-color: $color-primary-light;  
    }
}

.Card--Componist,
.Card--Composer {
    background-color: $color-secondary;
    .Card-tag,
    .Card-button {
        color: $color-secondary;
    }
    .Card-category {
        background-color: $color-secondary;  
    }
}

.Card--Presentator,
.Card--Presenter {
    background-color: $color-tertiary;

    .Card-tag,
    .Card-button {
        color: $color-tertiary;
    }
    
    .Card-category {
        background-color: $color-tertiary;  
    }
}
