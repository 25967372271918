.Header {
    height: 100vh;
    overflow: hidden;
    color: $color-white;
}

.Header-title {
    color: $color-white;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 28vh;
    right: 0;
    z-index: 7;
    transition: all ease-in-out .3s;
    
    h1 {
        // font-family: $font-header-title;
        color: $color-white;
        line-height: 1;
        font-size: 3em;

        @include media-query($breakpoint-xl) {
            font-size: 3.25em;
        }
        @include media-query($breakpoint-xxl) {
            font-size: 3.5em;
        }
    }
    
    span {
        display: block;
        margin: 0 0.25em;
    }
    
    @include media-query($breakpoint-xl) {
        width: 50%;
        top: 18vh;
    }

    @include media-query($breakpoint-xxl) {
        right: 5%;
       
    }
}
    
.Header-slider {
    overflow: hidden;
}

.Header-images {
    width: 100%;
}

.Header-menu {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 205;

    display: flex;
    align-items: center;
}

.Header-search {
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.Header-extra,
.Header-languages {
    font-size: 0.8em;
}
