.master-slider {
    // width: 50% !important;
}

.ms-bullet {
    border: 1px solid $color-white;
    background: none !important;
    width: 0.6em !important;
    height: 0.6em !important;
    border-radius: 100%;
}

.ms-bullet-selected {
    background-color: $color-white !important;
}

.ms-slide-info {
    color: $color-white;    
    position: absolute;
    width: 100% !important;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

// Styling of review
.ms-info {
    text-align: center;
    strong {
        font-size: 2em;
    }
    em {
        font-size: 0.9em;
        opacity: 0.7;
    }
}