.Search {
    margin-left: 1em;
}

.Search-form {
    display: flex;
    justify-content: space-between;
	width: auto;
	position: relative;
	margin: 0;
	overflow: hidden;
}

.Search-input {
    width: 100%;
    // Make room for absolute search icon
    padding-right: 2.25em;
    border: 0;
    margin: 0;
    z-index: 3;
	padding: 0.25em 0.5em;
    font-size: 0.8em;
    border-radius: 0;
}

.Search-label {
    cursor: pointer;
}

.Search-submit {
    appearance: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
	z-index: 3;
	background-color: $color-primary-light;
    color: $color-white;
	border: 0; 

	.Icon {
		margin-top: -0.2em;
	}
}

.search-results .Page {

    .Search-input {
        padding: 0.75em 1em;
        font-size: 1em;
    }
    .Search-submit {
        padding: 0.25em 1em;
        font-size: 1.5em;
    }
}