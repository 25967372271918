$breakpoint-m:			30em; //  480px
$breakpoint-ml:         35em;
$breakpoint-l:			40em; //  640px
$breakpoint-xl:			50em; //  880px
$breakpoint-xxl:		60em; //  960px
$breakpoint-xxxl:		80em; // 1280px
$breakpoint-xxxxl:		90em; // 1280px

// Fonts
$font-fallback-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-default: 'Montserrat', sans-serif;
$font-heading: 'Biryani', sans-serif; //'ZCOOL XiaoWei', serif; //'Marcellus', serif;
$font-header-title: 'Julius Sans One', sans-serif;

$color-gray-light:      #ddd;
$color-gray:            #bdbdbd;
$color-gray-dark:       #444; 
$color-black:           #000;
$color-white:           #fff;

$color-primary-light:   #54abb5; 
$color-primary:         #3c52a5; // blues
$color-primary-dark:    #2b4f80;

$color-secondary:       #2e538c;//#4c3f99; // purple
$color-secondary-light: #2e538c;

$color-tertiary:        #32986f;//#abb137; // green

$color-quaternary:      #2e538c;//#f58322; // orange

$color-quinary:         #32986f;//#dc2168; // pink

// Specials
$border-radius: 4px;
$border-radius-round: 6em;
$box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1);