/**
 * Horizontally centered content area.
 */

 .Container {
	margin: 0 auto;
	max-width: 70rem;
	padding: 0 1em;
}

// Modifier: full width container on smaller viewports
.Container--fullWidth {
	padding: 0;
}

@include media-query($breakpoint-m) {

	.Container {
		padding: 0 2em;
	}
}

@include media-query($breakpoint-xl) {

	.Container {
		padding: 0 3em;
	}
}

@include media-query($breakpoint-xxl) {

	.Container {
		padding: 0 3em;
	}
}

.Container--small {
	max-width: 60rem;
}

.Container--nolimit {
	max-width: inherit;
}
