.Background {
    padding: 3em 0;
    position: relative;
}

.Background--vh {
    min-height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    text-align: center;
}

.Background--primary {
    color: $color-white;
    background-color: #181818;

    h1,h2,h3,h4 {
        color: $color-white;
    }
    h2 {
        font-family: $font-header-title;
    }
}

.Background--secondary {
    color: $color-white;
    background-color: $color-secondary;

    h1,h2,h3,h4 {
        color: $color-white;
    }
}

.home #contact {
    display: none;
}