.Button {
	appearance: none;
	text-decoration: none;
	background: transparent;
	color: $color-white;
	display: inline-block;
	border: 0;
	cursor: pointer;
	transition: all .2s ease-in-out;
	padding: 0.35em 0.75em;
	background-color: $color-primary-light;
	margin-right: 0;

	&:hover,
	&:active
	&:focus {
		background-color: $color-quinary;
	}
}

.Button--white {
    background-color: $color-white;
    color: $color-primary;

    &:hover,
	&:active
	&:focus {
		background: $color-white;
		color: $color-primary-light;
	}
}

.Button--round {
    border-radius: $border-radius-round;
}

.Button--social {
	line-height: 0;
	height: 2em;
	width: 2em;
	padding: 0.5em;
	font-size: 1em;
}