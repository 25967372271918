.Page {
    position: relative;
    z-index: 3;
    margin-top: -10vh;

    @include media-query($breakpoint-l) {
        margin-top: -25vh;
    }
}

.Page-header {
    background-color: $color-white;
    color: $color-black;

    padding: 1em 1em 2em;
    margin-bottom: 2em;

    @include media-query($breakpoint-m) {
        padding: 1em 2em 2em;
    }
}

.Page-title {
    font-size: 1.75em;
    margin-bottom: 0.25em;
}

.Page-intro {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0;
    max-width: 60em;
}

.category-news,
.category-nieuws {
    .Page-header {
        display: none;
    }
}
