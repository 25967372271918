// WP Flexembed
.wp-block-embed {
	display: block;
	margin: 0 0 1em 0;
	overflow: hidden;
	position: relative;
}

.wp-has-aspect-ratio{
	display: block;
	padding-bottom: 100%;
	width: 100%;
}

.wp-embed-aspect-16-9 {
    padding-bottom: 56%;
    min-height: 220px;
}

.wp-block-embed__wrapper {
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.fluid-width-video-wrapper {
    padding-top: inherit;
}

.wp-block-embed__wrapper iframe {
	border: 0;
	height: 100%;
	width: 100%;
}

.wp-block-embed__wrapper div {
	height: 100%;
}


// WP Button
.wp-block-button {
    margin-bottom: 3.5em !important;

    .wp-block-button__link {
        text-decoration: none;
        background: $color-primary-light;
        color: $color-white;
        padding: 1em 2em 0.75em;
        display: inline-block;
        border: 0;
        cursor: pointer;
        transition: all .2s ease-in-out;
        line-height: 1.4;
        position: relative;
        font-size: 0.9em;
        font-family: $font-heading;
        border-radius: 0;
        text-transform: uppercase;
    
        &:hover,
        &:active,
        &:focus {
            background: $color-primary;
        }
    }
}

// Gallery 
.wp-block-gallery--small {
    .blocks-gallery-item {
        max-height: 230px;
    }
}

.wp-block-gallery {
    margin-bottom: 0;
    
    
    .blocks-gallery-item figcaption {
        padding-bottom: 1em;

        a {
            text-decoration: none;
            color: $color-white;
            display: block;
            background: rgba(245, 245, 245, 0.5);
            margin-top: 0.5em;
            padding: 0.4em 0 0 0;
            font-family: $font-heading;
        }
    }
}

//text en media
.wp-block-media-text.has-media-on-the-right {
    align-items: flex-start;

    .wp-block-media-text__media {
        margin-top: 0.4em;
        margin-bottom: 1em;
    }
    .wp-block-media-text__content {
        padding: 0 2em 0 0;
    }
}