/**
 * Implements the Sticky Footer pattern.
 *
 * On pages with less content the footer will still be positioned at the bottom
 * of the screen.
 *
 * See: http://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */

body {
	display: flex;
	flex-direction: column;
	// This needs the `html` tag to be set to `height: 100%`
	min-height: 100%;
}

// Make sure the content area will take up all available space in the
// container, and will be at least the height it needs for its content.
.Site-content {
	flex: 1 0 auto;
	width: 100%;
}

.Site-content--black {
	background-color: $color-black;
	color: $color-white;
	z-index: 0;
}

.home {
	min-height: 100vh;
	// overflow: hidden;
	position: relative;

	
	.Footer {
		background-color: $color-black;

		@include media-query($breakpoint-m) {
			background-color: transparent;
			position: absolute;
			bottom: 0;
			opacity: 0.5;

			.Container {
				max-width: inherit;
			}
		}
	}
}