.Footer {
    background-color: $color-primary-light;
    color: $color-white;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    // margin-top: 3em;
    font-size: 0.7em;
    width: 100%;

    p {
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
    }

}

.Footer-title {
    font-size: 1.75em;
    color: $color-white;
}

.Footer-content {

    @include media-query($breakpoint-m) {
        display: flex;
        justify-content: space-between;
    }
}