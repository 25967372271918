.u-linkClean {
	color: inherit;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: currentColor;
		text-decoration: underline;
	}
}

.u-linkprimary {
	color: $color-primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: $color-primary;
		text-decoration: underline;
	}
}

.u-linkCleanHover {
	color: inherit;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: currentColor;
		text-decoration: none;
	}
}
