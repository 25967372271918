
#menu-header-menu-en.menu,
#menu-header-menu-nl.menu {
    position: relative;
	list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    text-align: center;
    height: 100vh;
    font-size: 0.9em;

    @include media-query($breakpoint-xxl) {
        padding-left: 40%;
        font-size: 1em;
    }
 
    > .current-menu-item a {

        > .menu-image {
            z-index: -1;
        }
    }

    > .menu-item {
        
        > a {
            text-decoration: none;
            font-family: $font-heading;
            text-transform: uppercase;
            padding: 0.4em 1em 0;
            letter-spacing: 1px;
            font-weight: 800;
            font-size: 1.3em;
            background-color: $color-primary-light;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 0.5em;
            cursor: pointer;

            &:active,
            &:focus,
            &:hover {

                .menu-image {
                    z-index: -1;
                }
            }
        }
    }

    > .menu-home-button a {
        background: transparent;
        font-family: $font-header-title;
        font-size: 3em;
        max-width: 8em;
        text-align: center;
        line-height: 1.1;
        margin-bottom: 0.3em;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        margin-top: 40vh;

        @include media-query($breakpoint-l) {
            margin-top: 30vh;
        }

        @include media-query($breakpoint-xxl) {
            margin-top: 25vh;
        }
    }

    > .menu-item-1 a {
        background-color: $color-primary-light;
    }

    > .menu-item-2 a {
        background-color: $color-secondary;
    }

    > .menu-item-3 a {
        background-color: $color-tertiary;
        margin-bottom: 1em;
    }

    > .menu-item-small {
        display: inline-block;

        a {
            background-color: rgba($color-black, 0.75) !important;
            display: inline-block !important;
            padding: 0.2em 0.75em !important;
            text-transform: capitalize !important;
            font-weight: normal !important;
            font-family: $font-default !important;
            font-size: 0.9em !important;
            margin-right: 0.5em;
        }
    }

    .menu-image {
        z-index: -2;
        min-height: 100%;
        min-width: 600px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 55%;
        left: 30%;
        transform: translate(-50%, -50%);
        
        @include media-query($breakpoint-m) {
            top: 60%;
        }

        @include media-query($breakpoint-xl) {
            width: 50%;
            left: 20%;
            top: 50%;
        }
       
        @include media-query($breakpoint-xxxl) {
            top: 70%;
            width: 60%;
        }
    }

    .sub-menu {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
        height: 0;
        overflow: hidden;
    }

    // .menu-item-3 .menu-image {
    //     min-width: 520px;
    //     left: 40%;
    //     top: 50%;

    //     @include media-query($breakpoint-m) {
    //         top: 50%;
    //         width: 80%;
    //         min-width: 600px;

    //     }
    //     @include media-query($breakpoint-xl) {
    //         width: 50%;
    //         left: 30%;
    //     }
    // }
}