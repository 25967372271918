.Paging,
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.Paging-item,
.page-numbers {
    color: $color-primary-light;
    border: 1px solid $color-primary-light;
    padding: 0.75em 0.1em;
    cursor: pointer;
    border-radius: $border-radius-round;
    text-align: center;
    text-decoration: none;
    margin-right: 0.5em;
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    line-height: 1;

    &:active,
    &:hover,
    &:focus {
        background-color: $color-primary-light;
        color: $color-white;
    }

    &.prev,
    &.next {
        .Icon {
            margin-top: -0.2em;
        }
    }
}

.Paging-current,
.page-numbers.current {
    background-color: $color-primary-light;
    color: $color-white;
}

.screen-reader-text {
    display: none;
}