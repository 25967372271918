.Breadcrumbs {
    color: $color-gray;
    font-size: 0.75em;
    margin-left: -1em;
    // font-family: $font-heading;
    margin-bottom: 2em;
    
    a,
    .breadcrumb_last {
        margin-right: 1em;
        margin-left: 1em;
    }

    a {
        color: $color-gray-dark;
        text-decoration: none;

        &:hover {
            color: $color-primary;
        }

    }

    .breadcrumb_last {
        color: $color-gray;
    }
}
