*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}

body {
	min-height: 100%;
}

img {
	height: auto;
	// Make images fluid width ("responsive") by default
	max-width: 100%;
	// Remove whitespace below images (caused by line-height)
	vertical-align: middle;
}

a {
	color: currentColor;
}

p {
	margin-top: 0;
}

hr {
	border: 0;
    border-top: 1px solid white;
    margin-top: 2.5em;
    padding-bottom: 1em;
	opacity: 0.1;
	width: 100%;
}